import React, { useEffect } from 'react';

const Particles = () => {
  useEffect(() => {
    const canvas = document.getElementById("cvs");
    const context = canvas.getContext("2d");
    context.scale(window.devicePixelRatio, window.devicePixelRatio);
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    // const gradient = context.createLinearGradient(0, 0, canvas.width, canvas.height);
    // gradient.addColorStop(0, "#ffffff");
    // gradient.addColorStop(1.0, "#3662E3");
    // context.fillStyle = gradient;
    // context.strokeStyle = gradient;

    class Particle {
      constructor(effect) {
        this.effect = effect;
        this.radius = Math.floor(Math.random() * 5 + 2);
        this.x = this.radius + Math.random() * (this.effect.width - this.radius * 2);
        this.y = this.radius + Math.random() * (this.effect.height - this.radius * 2);
        this.vx = (Math.random() - 0.5) * 0.4;
        this.vy = (Math.random() - 0.5) * 0.4;
        this.p = [
          new Path2D('M40 32a24 24 0 1 1 48 0A24 24 0 1 1 40 32zM456 160a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm24 264a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM172.7 147.3c-6.2-6.2-6.2-16.4 0-22.6l8.3-8.3c18-18 29.3-41.6 32.1-66.8l3-27.4c1-8.8 8.9-15.1 17.7-14.1s15.1 8.9 14.1 17.7l-3 27.4c-3.6 32.5-18.2 62.8-41.3 85.9l-8.3 8.3c-6.2 6.2-16.4 6.2-22.6 0zm192 169.4l8.3-8.3c23.1-23.1 53.4-37.7 85.9-41.3l27.4-3c8.8-1 16.7 5.4 17.7 14.1s-5.4 16.7-14.1 17.7l-27.4 3c-25.3 2.8-48.8 14.1-66.8 32.1l-8.2 8.2c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6zm135.5-303c5.7 6.7 4.9 16.8-1.9 22.5l-10 8.5C475 55.9 458.1 61.9 440.7 61.4c-21-.6-38.6 15.6-39.8 36.5l-1.8 30c-2.2 38.2-34.4 67.7-72.6 66.6c-9.5-.3-18.8 3-26.1 9.2l-10 8.5c-6.7 5.7-16.8 4.9-22.5-1.9s-4.9-16.8 1.9-22.5l10-8.5c13.3-11.2 30.3-17.2 47.7-16.7c21 .6 38.6-15.6 39.8-36.5l1.8-30c2.2-38.2 34.4-67.7 72.6-66.6c9.5 .3 18.8-3 26.1-9.2l10-8.5c6.7-5.7 16.8-4.9 22.6 1.9zM32.1 479.6c0 0 .1 .1 .1 .1s.1 .1 .1 .1l76.2-28.6L60.7 403.4 32.1 479.6zm169.6-63.2L95.6 310.3 73 370.7c.8 .6 1.6 1.2 2.3 1.9l64 64c.7 .7 1.4 1.5 2 2.4l60.5-22.7zM234.7 404l53.3-20c0-.1 0-.1 .1-.2s0-.1 0-.2L128.3 224c-.1 0-.1 0-.2 0l-.1 0-.1 0-20 53.3L234.7 404zM98.1 212.6c8-21.4 36.4-27.6 52.6-11.4L310.9 361.3c16.2 16.2 10 44.6-11.4 52.6L43.3 510C18 519.4-7.4 494 2 468.8L98.1 212.6z'),
          new Path2D('M448 64V448H576c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H448zm-32 0H224V448H416V64zM192 448V64H64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H192zM0 96C0 60.7 28.7 32 64 32H576c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z'),
          new Path2D('M32 240c0-91.4 93.8-176 224-176s224 84.6 224 176s-93.8 176-224 176c-32 0-63-5.9-93.5-16.2c-9.9-3.3-20.7-1.8-29.2 4.2c-15.4 10.7-31.4 20-48.4 27.4L91.2 446l-6.4-14.7c-10.5 4.6-21.5 8.6-32.7 11.5c.5-.9 1.1-1.8 1.6-2.6C68 416.5 76.3 391 79.4 363.9c1-9.2-2.1-17.7-7.4-23.9c-24.5-29.4-40-62.9-40-100zM256 32C114.5 32 0 125.2 0 240c0 46.6 19.6 87.1 47.4 120.5c0 .1 .1 .1 .1 .2c-2.6 22.6-9.5 43.5-21.3 63.1c-6.5 10.8-13.5 20.8-21.7 29c-4.5 4.6-5.9 11.5-3.4 17.4s8.3 9.8 14.8 9.8c28.7 0 57.6-8.9 81.6-19.3c19.2-8.4 37.1-18.7 53.9-30.5c.2-.1 .5-.2 .7-.1C185.5 441.3 220 448 256 448c141.5 0 256-93.2 256-208S397.5 32 256 32zM176 221.3c0-16.2 13.1-29.3 29.3-29.3c7.8 0 15.2 3.1 20.7 8.6l18.7 18.7c6.2 6.2 16.4 6.2 22.6 0l18.7-18.7c5.5-5.5 12.9-8.6 20.7-8.6c16.2 0 29.3 13.1 29.3 29.3c0 7.8-3.1 15.2-8.6 20.7L256 313.4l-71.4-71.4c-5.5-5.5-8.6-12.9-8.6-20.7zM205.3 160c-33.8 0-61.3 27.4-61.3 61.3c0 16.2 6.5 31.8 17.9 43.3l82.7 82.7c6.2 6.2 16.4 6.2 22.6 0l82.7-82.7c11.5-11.5 17.9-27.1 17.9-43.3c0-33.8-27.4-61.3-61.3-61.3c-16.2 0-31.8 6.5-43.3 17.9l-7.4 7.4-7.4-7.4c-11.5-11.5-27.1-17.9-43.3-17.9z'),
          new Path2D('M98.1 186.2C73.2 233.5 95.7 292 146 310.3s105.1-12 116.5-64.3l10.9-50.1L122 140.8 98.1 186.2zm182.1-21.9l19.2-88.6L179.3 32 137 112.2l143.2 52.1zm-210.4 7L150.9 17.1c7.5-14.2 24.2-20.6 39.3-15.2L310.4 45.7c3.6 1.3 6.8 3.2 9.6 5.6c2.8-2.4 6-4.3 9.6-5.6L449.8 1.9c15-5.5 31.8 1 39.3 15.2l81.2 154.2c31.4 59.7 7.4 132.3-50.6 162.6l42 115.4 56.9-20.7c8.3-3 17.5 1.3 20.5 9.6s-1.3 17.5-9.6 20.5l-71.9 26.2L485.6 511c-8.3 3-17.5-1.3-20.5-9.6s1.3-17.5 9.6-20.5l56.9-20.7-42-115.4c-63.9 14.1-129-26.1-143.3-92L320 131.8l-26.2 121c-14.3 65.9-79.4 106.2-143.3 92l-42 115.4L165.4 481c8.3 3 12.6 12.2 9.6 20.5s-12.2 12.6-20.5 9.6L82.5 484.9 10.5 458.7c-8.3-3-12.6-12.2-9.6-20.5s12.2-12.6 20.5-9.6l56.9 20.7 42-115.4c-58-30.3-82-102.9-50.6-162.6zm472.1 14.9L518 140.8 366.6 195.9 377.5 246c11.3 52.3 66.2 82.5 116.5 64.3s72.8-76.7 47.9-124.1zM359.8 164.3L503 112.2 460.7 32 340.6 75.7l19.2 88.6z'),
          new Path2D('M32 240c0-91.4 93.8-176 224-176s224 84.6 224 176s-93.8 176-224 176c-32 0-63-5.9-93.5-16.2c-9.9-3.3-20.7-1.8-29.2 4.2c-15.4 10.7-31.4 20-48.4 27.4L91.2 446l-6.4-14.7c-10.5 4.6-21.5 8.6-32.7 11.5c.5-.9 1.1-1.8 1.6-2.6C68 416.5 76.3 391 79.4 363.9c1-9.2-2.1-17.7-7.4-23.9c-24.5-29.4-40-62.9-40-100zM256 32C114.5 32 0 125.2 0 240c0 46.6 19.6 87.1 47.4 120.5c0 .1 .1 .1 .1 .2c-2.6 22.6-9.5 43.5-21.3 63.1c-6.5 10.8-13.5 20.8-21.7 29c-4.5 4.6-5.9 11.5-3.4 17.4s8.3 9.8 14.8 9.8c28.7 0 57.6-8.9 81.6-19.3c19.2-8.4 37.1-18.7 53.9-30.5c.2-.1 .5-.2 .7-.1C185.5 441.3 220 448 256 448c141.5 0 256-93.2 256-208S397.5 32 256 32zM192 216a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm128 0a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM155.9 284.1c-6.6 5.9-7.1 16-1.3 22.6C179.5 334.5 215.7 352 256 352s76.5-17.5 101.4-45.3c5.9-6.6 5.3-16.7-1.3-22.6s-16.7-5.3-22.6 1.3C314.5 306.6 286.8 320 256 320s-58.5-13.4-77.5-34.7c-5.9-6.6-16-7.1-22.6-1.3z'),
          new Path2D('M32 256C32 132.3 132.3 32 256 32s224 100.3 224 224c0 108.4-76.9 198.8-179.2 219.5c-8.7 1.8-14.3 10.2-12.5 18.9s10.2 14.3 18.9 12.5C424 483.2 512 379.9 512 256C512 114.6 397.4 0 256 0S0 114.6 0 256c0 23.6 3.2 46.5 9.2 68.3c2.4 8.5 11.2 13.5 19.7 11.2s13.5-11.2 11.2-19.7c-5.2-19-8.1-39-8.1-59.7zm165.1 .8c-8.4-2.8-17.4 1.7-20.2 10.1s1.7 17.4 10.1 20.2l70.5 23.5L160 348.8V320c0-26.5-21.5-48-48-48s-48 21.5-48 48V440c0 39.8 32.2 72 72 72h47.8c29.4 0 55.9-17.9 66.9-45.3l23.9-59.6 47-18.4c20.9-8.2 32.8-29.4 30.1-50.7c-2.3-18.7-15.1-34.7-33.3-40.8L197.1 256.8zM320 342.7c0 .4 0 .9 0 1.3h0c0 6.4-3.9 12.4-10.2 14.9l-53.5 20.9c-4.3 1.7-7.7 5.2-9.2 9.5c-.1 .2-.1 .4-.2 .6l-26 64.9C214.8 470 200.1 480 183.8 480H136c-22.1 0-40-17.9-40-40V320c0-8.8 7.2-16 16-16s16 7.2 16 16v52.2c0 5.3 2.6 10.2 7 13.2s9.9 3.6 14.9 1.7l148.3-58c8.2-3.2 17.5 .8 20.7 9.1c.6 1.5 .9 3 1.1 4.5zM200.4 192a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm136 40a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm-120-87.2l13.2 11.3c6.7 5.8 16.8 5 22.6-1.7s5-16.8-1.7-22.6l-13.2-11.3C206.4 94.1 162.3 90 127.1 110.1l-7.1 4c-7.7 4.4-10.3 14.2-6 21.8s14.2 10.3 21.8 6l7.1-4c23.5-13.4 52.9-10.6 73.4 7z'),
          new Path2D('M32 176c0-74.8 73.7-144 176-144s176 69.2 176 144s-73.7 144-176 144c-15.3 0-30.6-1.9-46.3-5c-3.5-.7-7.1-.2-10.2 1.4c-6.1 3.1-12 6-18 8.7c-28.4 12.9-60.2 23.1-91.5 26c14.9-19 26.8-39.7 37.6-59.9c3.3-6.1 2.3-13.6-2.5-18.6C50 244.2 32 213.1 32 176zM208 0C93.1 0 0 78.9 0 176c0 44.2 19.8 80.1 46 110c-11.7 21-24 40.6-39.5 57.5l0 0-.1 .1c-6.5 7-8.2 17.1-4.4 25.8C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.9-2.2 9.6-4.5 14.3-6.8c15.3 2.8 30.9 4.6 47 4.6c114.9 0 208-78.9 208-176S322.9 0 208 0zM447.4 160.5C541.6 167 608 233 608 304c0 37.1-18 68.2-45.1 96.6c-4.8 5-5.8 12.5-2.5 18.6c10.9 20.2 22.7 40.8 37.6 59.9c-31.3-3-63.2-13.2-91.5-26c-6-2.7-11.9-5.6-18-8.7c-3.2-1.6-6.8-2.1-10.2-1.4c-15.6 3.1-30.9 5-46.3 5c-68.2 0-123.6-30.7-153.1-73.3c-11 3-22.3 5.2-33.8 6.8C279 439.8 349.9 480 432 480c16.1 0 31.7-1.8 47-4.6c4.6 2.3 9.4 4.6 14.3 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.7 2-18.9-4.4-25.8l-.1-.1 0 0c-15.5-17-27.8-36.5-39.5-57.5c26.2-29.9 46-65.8 46-110c0-94.4-87.8-171.5-198.2-175.8c2.8 10.4 4.7 21.2 5.6 32.3zM539.3 266c-6.2-6.2-16.4-6.2-22.6 0l-74 74-31.4-31.4c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L431.4 374c6.2 6.2 16.4 6.2 22.6 0l85.3-85.3c6.2-6.2 6.2-16.4 0-22.6zM147.9 104.1l-.3 .9c-3 8.3 1.4 17.5 9.7 20.4s17.5-1.4 20.4-9.7l.3-.9c.9-2.7 3.5-4.4 6.3-4.4h41.3c6.5 0 11.7 5.3 11.7 11.7c0 4.2-2.2 8.1-5.9 10.2l-31.4 18c-5 2.9-8 8.1-8 13.9v9.5c0 8.8 7.2 16 16 16s16-7.2 16-16v-.3L247.4 160c13.6-7.8 22-22.3 22-37.9c0-24.2-19.6-43.7-43.7-43.7H184.3c-16.4 0-31 10.3-36.4 25.7zM230.7 236a22.7 22.7 0 1 0 -45.3 0 22.7 22.7 0 1 0 45.3 0z'),
        ][Math.floor(Math.random() * 7)];
      }

      draw(context) {
        context.beginPath();
        // Moved square
        context.translate(this.x, this.y);
        context.scale(0.05, 0.05);
        context.fillStyle = "#3662E311";
        // context.fillRect(0, 0, 80, 80);
        // context.translate(this.x, this.y);
        context.fill(this.p);

        // Reset current transformation matrix to the identity matrix
        context.setTransform(1, 0, 0, 1, 0, 0);
        // context.setScale(1, 1);
        // set color
        // context.fillStyle = "#3662E322";
        // context.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        // context.rect(this.x, this.y, 100, 100);

        // let p = new Path2D('M10 10 h 80 v 80 h -80 Z');
        // context.translate(this.x, this.y);
        // context.fill(p);
        // var data = '<svg xmlns="http://www.w3.org/2000/svg" width="300" height="200">' +
        //   '<foreignObject width="100%" height="100%">' +
        //   '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size:50px">' +
        //   'Simply Easy ' +
        //   '<span style="color:blue;">' +
        //   'Learning</span>' +
        //   '</div>' +
        //   '</foreignObject>' +
        //   '</svg>';
        // var DOMURL = window.URL || window.webkitURL || window;
        // var img1 = new Image();
        // var svg = new Blob([data], { type: 'image/svg+xml' });
        // var url = DOMURL.createObjectURL(svg);
        // img1.onload = function () {
        //   context.drawImage(img1, 25, 70);
        //   DOMURL.revokeObjectURL(url);
        // }
        // img1.src = url;
        // let p = new Path2D('M10 10 h 80 v 80 h -80 Z');
        // context.translate(this.x, this.y);
        // context.fill(p);

        context.fill();
      }
      update() {
        this.x += this.vx;
        this.y += this.vy;
        if (this.x > this.effect.width || this.x < 0) this.vx *= -1;
        if (this.y > this.effect.height || this.y < 0) this.vy *= -1;
      }
    }
    class Effect {
      constructor(canvas) {
        this.canvas = canvas;
        this.width = this.canvas.width;
        this.height = this.canvas.height;
        this.particles = [];
        if (window.innerWidth < 768) {
          this.numberOfParticles = 20;
        } else if (window.innerWidth < 1024) {
          this.numberOfParticles = 40;
        } else {
          this.numberOfParticles = 60;
        }
        this.numberOfParticles = 200;
        this.createParticles();
      }
      createParticles() {
        for (let i = 0; i < this.numberOfParticles; i++) {
          this.particles.push(new Particle(this));
        }
      }
      handleParticles() {
        this.particles.forEach((particle) => {
          particle.draw(context);
          particle.update();
          // this.connectParticles(context);
        });
      }

      connectParticles(context) {
        const maxDistance = 200;
        for (let a = 0; a < this.particles.length; a++) {
          for (let b = a; b < this.particles.length; b++) {
            const dx = this.particles[a].x - this.particles[b].x;
            const dy = this.particles[a].y - this.particles[b].y;
            const distance = Math.hypot(dx, dy);
            if (distance < maxDistance) {
              const opacity = 1 - distance / maxDistance;
              context.globalAlpha = opacity;
              context.beginPath();
              context.moveTo(this.particles[a].x, this.particles[a].y);
              context.lineTo(this.particles[b].x, this.particles[b].y);
              context.stroke();
              context.restore();
            }
          }
        }
      }
    }
    const effect = new Effect(canvas);
    function animate() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      window.requestAnimationFrame(animate);
      effect.handleParticles(context);
    }
    animate();
  }, []);

  return <canvas id="cvs" className="absolute -z-10"></canvas>
};

export default Particles;
