"use client";

import React from "react";
import { LoadingIndicator } from "@/components/LoadingIndicator";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isPrimary?: boolean;
  isSecondary?: boolean;
  isTertiary?: boolean;
  isLink?: boolean;
  isMini?: boolean;
  isNoBackground?: boolean;
  isAdminOnlyActionNotice?: boolean;
  playSuccessAnimation?: boolean;
  isDestructive?: boolean;
  isLoading?: boolean;
  iconRight?: boolean;
  children: React.ReactNode;
  className?: string;
  testID?: string;
  icon?: React.ReactNode;
}

const Button = React.forwardRef(
  (
    {
      playSuccessAnimation,
      isPrimary,
      isSecondary,
      isTertiary,
      isDestructive,
      isAdminOnlyActionNotice,
      isNoBackground,
      testID,
      isLink,
      isMini,
      isLoading,
      className,
      children,
      icon,
      iconRight,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const baseStyle = `font-sans font-medium rounded ${
      isMini ? "py-1.5 px-3" : "py-2 px-4"
    }`;
    let styles = ``;
    if (isPrimary) {
      styles = `bg-srPrimary enabled:hover:bg-srPrimaryDark disabled:bg-srPrimary10 text-white`;
    } else if (isSecondary) {
      styles = `text-srPrimaryText bg-gray-50 hover:bg-gray-100`;
    } else if (isTertiary) {
      styles = `text-srPrimaryText bg-white hover:bg-gray-100 enabled:hover:bg-white`;
    } else if (isDestructive) {
      styles = `bg-srError hover:bg-srErrorDark text-white`;
    } else if (isNoBackground) {
      styles = `text-srPrimaryText bg-transparent hover:bg-gray-100`;
    }
    if (isMini) {
      styles += ` text-sm`;
    }
    const renderButton = () => (
      <button
        ref={ref}
        data-test={testID || children}
        type="button"
        className={`${
          playSuccessAnimation ? " bg-srSuccess hover:bg-srSuccess" : styles
        } flex items-center whitespace-nowrap justify-center ${baseStyle} ${
          className || ``
        }`}
        {...rest}
      >
        {icon && !iconRight && <div className="-ml-1 mr-1 h-5 w-5">{icon}</div>}
        <div
          className={`${(isLoading || playSuccessAnimation) && "invisible"}`}
        >
          {children}
        </div>
        {icon && iconRight && <div className="ml-1 -mr-1 h-5 w-5">{icon}</div>}
        {isLoading && (
          <div className="absolute">
            <LoadingIndicator isLight={isPrimary} />
          </div>
        )}
        {playSuccessAnimation && (
          <div className="absolute">
            <CheckCircleIcon className="text-white w-8 h-8" />
          </div>
        )}
      </button>
    );
    if (isAdminOnlyActionNotice) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{renderButton()}</TooltipTrigger>
            <TooltipContent>
              <div className="px-1 py-2">
                <div className="font-bold">Organization Admin Only</div>
                <div className="text-small pt-0.5">
                  You have to be an admin to do this!
                </div>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    } else {
      return renderButton();
    }
  }
);

Button.displayName = "Button";

export default Button;
